<template>
  <TepmplateBlock mod-class content-class="mt-4 d-block" title-page="Чаты">
    <help-popover
      :message="helpPopovers.PopoverOfTitlePage"
      class="ml-2 help-icon"
      :is-inline-block="false"
      style="right: calc(50% + 65px); top: 15px"
    />
    <help-popover
      :message="helpPopovers.PopoverOfIconPlus"
      class="ml-2 help-icon"
      :is-inline-block="false"
      style="right: calc(50% + 27px); top: 10px"
    />
    <help-popover
      :message="helpPopovers.PopoverOfIconPlus"
      class="ml-2 help-icon"
      :is-inline-block="false"
      style="position: absolute; right: 25px; bottom: 100px"
    />
    <template #titleAppend>
      <IconPlus @clicked="showModalForm" />
    </template>
    <!------------------------ modal ----------------->
    <b-modal
      id="created-modal"
      title="Создание группового чата"
      hide-footer
      @hide="clearCreateForm"
    >
      <form ref="form" @submit.stop.prevent="createChat">
        <b-form-group label-for="name-input">
          <label class="create-chat__label">Введите название чата:</label>
          <b-form-input id="name-input" v-model="chatName" />

          <hr />

          <label class="create-chat__label"
            >Выберите пользователей, которых вы хотите добавить:</label
          >

          <b-input
            v-model="searchUserForAddToChat"
            class="mb-3"
            placeholder="Начните вводить имя или email"
            type="search"
          />

          <b-form-group
            v-slot="{ ariaDescribedby }"
            class="users-list custom-scrollbar"
          >
            <transition-group name="list">
              <b-form-checkbox
                v-for="option in filteredUsers"
                v-model="selectedUsers"
                :key="option.id"
                :value="option.id"
                :aria-describedby="ariaDescribedby"
              >
                {{ getUserInfo(option) }}
                <span class="email">{{ option.email }}</span>
              </b-form-checkbox>
            </transition-group>
          </b-form-group>

          <div class="mt-4 create-chat-btn" type="submit" @click="createChat">
            Создать
          </div>
        </b-form-group>
      </form>
    </b-modal>

    <b-modal
      v-model="isEditChatModalVisible"
      title="Редактирование чата"
      hide-footer
      @hide="hideEditChatModal"
    >
      <form ref="form" @submit.stop.prevent="createChat">
        <b-form-group label-for="name-input">
          <label class="create-chat__label">Введите название чата:</label>
          <b-form-input id="name-input" v-model="editableChat.title" />

          <hr />

          <label class="create-chat__label"
            >Выберите пользователей, которых вы хотите добавить:</label
          >

          <b-input
            v-model="searchUserForAddToChat"
            class="mb-3"
            placeholder="Начните вводить имя или email"
            type="search"
          />

          <b-form-group
            v-slot="{ ariaDescribedby }"
            class="users-list custom-scrollbar"
          >
            <transition-group name="list">
              <b-form-checkbox
                v-for="option in filteredUsers"
                v-model="selectedUsers"
                :key="option.id"
                :value="option.id"
                :aria-describedby="ariaDescribedby"
              >
                {{ getUserInfo(option) }}
                <span class="email">{{ option.email }}</span>
              </b-form-checkbox>
            </transition-group>
          </b-form-group>

          <div
            class="mt-4 create-chat-btn"
            type="submit"
            @click="saveEditableChat"
          >
            Сохранить
          </div>
        </b-form-group>
      </form>
    </b-modal>

    <div
      class="d-flex justify-content-center align-items-center mt-4"
      v-if="isShowSpinner"
    >
      <b-spinner />
    </div>

    <div class="chats" v-else>
      <div class="chats-available position-relative">
        <div>
          <h4>
            Чаты
          </h4>

          <div class="chats-action c-pointer mb-3">
            <b-input
              class="chats-action__search"
              placeholder="Поиск"
              type="search"
              trim
              v-model="searchGroupChatInput"
            />
          </div>
        </div>

        <div
          v-if="filteredGroupedChat.length"
          class="custom-scrollbar chats-list"
        >
          <transition-group name="list">
            <div
              v-for="chat in filteredGroupedChat"
              :key="chat.id"
              @click="activeChat = parseInt(chat.id)"
              class="c-pointer select-chat d-flex align-items-center justify-content-between"
              :class="[
                activeChat === parseInt(chat.id) ? 'active-chat' : '',
                chat.type !== 'group' ? 'type-private' : 'type-group',
                parseInt(chat.id) === managerChatId ? 'manager-chat' : ''
              ]"
              :ref="`chat-${chat.id}-link`"
            >
              <div>{{ chat.title }}</div>
              <div
                class="select-chat__new-messages"
                :class="activeChat === chat.id ? 'active' : ''"
                v-if="chat.newMessagesCount"
              >
                {{ chat.newMessagesCount }}
              </div>
            </div>
          </transition-group>
        </div>
      </div>
      <div class="chats-chat-component">
        <chat
          v-if="activeChat"
          :id="activeChat"
          :key="activeChat"
          :users="users"
          :is-use-old-chat="true"
          :chatKey="chatKey"
          @deleteChat="deleteChat"
          @editChat="editChat"
        />

        <div class="not-found-active" v-else>
          <h2 class="p-3">Выберите чат</h2>
        </div>
      </div>
    </div>

    <b-button
      class="plus-btn"
      title="Добавление нового чата"
      @click="showModalForm"
    ></b-button>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import RequestManager from "../../function/request/RequestManager";
import Chat from "../../components/Chat/Chat";
import { uniqBy } from "../../function/lodash";
import IconPlus from "../../components/Form/IconPlus";
import helpPopover from "../../components/HelpPopover";
// import colleaguesContacts from "../User/ColleaguesContacts";
import { CompanyApi as ICompanyApi } from "@/function/request/apiV2";
import eventBus from "@/utils/event-bus";

export default {
  name: "Chats",
  components: { IconPlus, TepmplateBlock, Chat, helpPopover },
  data() {
    return {
      companyApi: new ICompanyApi(),
      helpPopovers: {
        PopoverOfChat: "Общее количество чатов",
        PopoverOfTitlePage:
          "Раздел чаты, здесь можно найти все или отфильтровать нужные чаты",
        PopoverOfIconPlus: "Кнопка создания нового чата"
      },
      isShowSpinner: false,
      chatList: [],
      chatCount: 0,
      users: [],
      allUsers: [],
      chatName: "",
      activeChat: undefined,
      selectedUsers: [],
      crm_manager: undefined,
      intervalId: undefined,
      plusIcon: require("../../resource/img/plus-big.svg"),
      searchGroupChatInput: "",
      searchUserForAddToChat: "",
      managerChatId: 0,
      // managerChat: {},
      contacts: [],
      currentUser: undefined,
      chatKey: 0,
      editableChat: {},
      isEditChatModalVisible: false
    };
  },
  computed: {
    filteredUsers() {
      const email = this.users.filter(el =>
        el.email
          ?.toLowerCase()
          .includes(this.searchUserForAddToChat?.toLowerCase())
      );

      const firstName = this.users.filter(el =>
        el.firstName
          ?.toLowerCase()
          .includes(this.searchUserForAddToChat?.toLowerCase())
      );

      const lastName = this.users.filter(el => {
        return el.lastName
          ?.toLowerCase()
          .includes(this.searchUserForAddToChat?.toLowerCase());
      });

      let allSearched = [...email, ...firstName, ...lastName];
      return uniqBy(allSearched, "id");
    },
    companyInfo() {
      return this.$store.getters.mainCompanyInfo;
    },
    filteredGroupedChat() {
      return this.chatList.filter(el => {
        if (el && el.title) {
          return (
            el.title
              ?.toLowerCase()
              .includes(this.searchGroupChatInput?.toLowerCase()) ||
            parseInt(el.chatId) === this.managerChatId
          );
        } else {
          return [];
        }
      });
    }
  },
  methods: {
    showIds() {
      let foundContact = {};
      this.contacts.forEach(contact => {
        if (
          this.$route?.params?.dataUsers &&
          this.$route?.params?.dataUsers.id &&
          (parseInt(this.$route?.params?.dataUsers.id) ===
            parseInt(contact.chatId) ||
            parseInt(this.$route?.params?.dataUsers.id) ===
              parseInt(contact.user.id))
        ) {
          foundContact = contact;
        }
      });
      if (this.$route?.params?.dataUsers) {
        if (foundContact.chatId) {
          this.activeChat = parseInt(foundContact.chatId);
        } else {
          this.activeChat = -1;
        }
      } else {
        this.activeChat = parseInt(this.managerChatId);
      }
    },

    showModalForm() {
      this.$bvModal.show("created-modal");
    },
    clearCreateForm() {
      this.chatName = "";
      this.selectedUsers = [];
    },
    addUsersToChat(chatId) {
      const acl = {};
      this.selectedUsers.forEach(el => (acl[el] = ["chat_write", "chat_read"]));

      RequestManager()
        .putAcl("chats", "chat", chatId, acl)
        .then(() => {
          this.selectedUsers = [];
          this.chatName = "";
          this.$bvModal.hide("created-modal");
        });
    },
    deleteChat(chatId) {
      RequestManager()
        //.deleteChat(chatId)
        .chatApi.deleteChat(chatId)
        .then(() => {
          this.activeChat = undefined;
          this.chatList = [];
          this.getAllChats();
        });
    },
    editChat(chatId) {
      const foundChat = this.chatList.find(
        chat => chat.id === chatId.toString()
      );
      if (foundChat) {
        this.editableChat = foundChat;
        this.selectedUsers = foundChat.permissions.map(perm => perm.user.id);
        this.isEditChatModalVisible = true;
      }
    },
    hideEditChatModal() {
      this.editableChat = {};
      this.selectedUsers = [];
      this.isEditChatModalVisible = false;
    },
    async saveEditableChat() {
      let newPermissions = [];
      this.selectedUsers.forEach(userId => {
        newPermissions.push({
          user: {
            id: userId
          },
          roles: ["canWrite", "canRead"]
        });
      });
      await RequestManager().chatApi.editChat(this.editableChat.id, {
        title: this.editableChat.title,
        permissions: newPermissions
      });
      // this.$refs[`chat-${this.editableChat.id}-link`][0].click();
      this.activeChat = undefined;
      setTimeout(() => {
        this.$refs[`chat-${this.editableChat.id}-link`][0].click();
        this.hideEditChatModal();
      }, 100);
    },
    createChat() {
      if (this.chatName.length) {
        this.chatList = [];
        const permissions = [];
        this.selectedUsers.forEach(selectedUser => {
          permissions.push({
            user: {
              id: selectedUser
            },
            roles: ["admin"]
          });
        });
        permissions.push({
          user: {
            id: this.currentUser.id.toString()
          },
          roles: ["admin"]
        });
        RequestManager()
          .chatApi.createChat({
            title: this.chatName,
            permissions
          })
          .then(res => {
            this.updateChatsList();
            // this.addUsersToChat(res.id);
            this.sendCreatedMessage(res.id);
            this.$bvModal.hide("created-modal");
          });
      } else {
        this.$bvToast.toast("Введите название чата", {
          variant: "danger",
          title: "Ошибка"
        });
      }
    },
    sendCreatedMessage(chatId) {
      const message = "Чат создан";
      const data = new FormData();
      data.append("text", message);
      RequestManager().sendMessage(chatId, data);
    },
    getUserInfo(user) {
      return `${user.lastName} ${user.firstName}`;
    },
    sortChatList() {
      if (this.managerChatId > 0) {
        const managerChatIdx = this.chatList.findIndex(
          chat => parseInt(chat.id) === this.managerChatId
        );
        /* const newChatList = [
          this.chatList[managerChatIdx],
          ...this.chatList.slice(0, managerChatIdx),
          ...this.chatList.slice(managerChatIdx + 1)
        ]; */
        const newChatList = [
          this.chatList[managerChatIdx],
          ...this.chatList.filter(
            chat =>
              parseInt(chat.id) !== this.managerChatId &&
              chat.newMessagesCount > 0
          ),
          ...this.chatList.filter(
            chat =>
              parseInt(chat.id) !== this.managerChatId &&
              chat.newMessagesCount === 0
          )
        ];
        this.chatList = newChatList;
      }
    },
    async updateChatsList() {
      // const res = await RequestManager().contactApi.getContacts();
      const res = await RequestManager().chatApi.listChats({
        limit: 10000,
        offset: 0,
        type: ["contact", "group"]
      });
      this.chatCount = res.count;
      this.chatList = res.items;
      this.sortChatList();
    },
    async getAllChats() {
      this.isShowSpinner = true;
      try {
        let res = await RequestManager().contactApi.getContacts();
        this.contacts = res.items;
        this.users = this.contacts.map(contact => contact.user);
        const foundCRMManagerContact = this.contacts.find(
          cnt => cnt.user.id === this.companyInfo.crm_manager.toString()
        );
        if (!foundCRMManagerContact) {
          const response = await RequestManager().contactApi.createContact({
            user: {
              id: this.companyInfo.crm_manager.toString()
            },
            title: "Персональный менеджер",
            metadata: []
          });
          this.managerChatId = parseInt(response.chatId);
          res = await RequestManager().contactApi.getContacts();
        } else {
          this.managerChatId = parseInt(foundCRMManagerContact.chatId);
        }
        res = await RequestManager().chatApi.listChats({
          limit: 10000,
          offset: 0,
          type: ["contact", "group"]
        });
        this.chatCount = res.count;
        this.chatList = res.items;
        this.sortChatList();
      } finally {
        this.isShowSpinner = false;
        await this.showIds();
      }
    },
    async getAllUsers() {
      RequestManager()
        .contactApi.getContacts()
        .then(res => {
          this.allUsers = res.items;
          this.users = this.allUsers
            .filter(contact => contact.user.id !== this.currentUser.id)
            .map(c => c.user);
        });
      /* .getUsersList()
        .then(res => {
          this.allUsers = [...this.allUsers, ...res.results];
          this.users = [...this.users, ...res.results].filter(
            el => el.company === this.companyInfo.id
          );

          const usersWithoutCurrentUser = this.users.filter(
            user => user.id !== this.currentUser.id
          );

          this.initPersonalChat(usersWithoutCurrentUser);
        }); */
    },
    async initPersonalChat(users) {
      try {
        await Promise.allSettled(
          users.map(async user => {
            await RequestManager().personalChatCreate(user.id);
          })
        );
      } catch (e) {
        throw new Error(e);
      }
    }
  },
  async created() {
    this.getAllChats();
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  },
  async mounted() {
    this.intervalId = setInterval(this.updateChatsList, 4000);
    eventBus.$on("chat_new-message", message => {
      const foundChat = this.chatList.find(
        item => item.id === message.payload.id
      );
      if (foundChat) {
        foundChat.newMessagesCount += 1;
        this.chatKey += 1;
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  }
};
</script>

<style scoped lang="scss">
.chats {
  height: calc(100% - 25px);
  display: grid;
  grid-template-columns: 280px 1fr;

  &-available {
    padding: 20px 10px 10px 10px;
    background-color: var(--main-card-color);
    border-right-color: #dedede;
    border-left: 1px #dedede solid;
    border-top: 1px #dedede solid;
    border-bottom: 1px #dedede solid;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    min-height: 568px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &-chat-component {
    background-color: var(--bg-chat-gradient);
    background-image: var(--chat-gradient);
    border: 1px #dedede solid;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    .not-found-active {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      height: 100%;
    }
  }

  .select-chat {
    color: #007bff;
    border: 1px var(--main-card-color) solid;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #9dd6e138;

    &:hover {
      text-decoration: underline;
      background-color: #9dd6e1 !important;
      color: var(--main-card-color);
    }

    &__new-messages {
      line-height: 1;
      margin-left: 5px;
      display: inline-block;
      background-color: #17a2b8;
      border-radius: 4px;
      padding: 2px 8px;
      color: var(--main-card-color);
      font-weight: bold;
    }

    &__new-messages.active {
      line-height: 1;
      margin-left: 5px;
      display: inline-block;
      background-color: var(--main-card-color);
      border-radius: 4px;
      padding: 2px 8px;
      color: #17a2b8;
      font-weight: bold;
    }
  }

  .select-chat.active-chat {
    color: var(--main-card-color);
    background-color: #17a2b8;
  }

  .manager-chat {
    background-color: var(--bg-chat-gradient);
    color: var(--main-text-color);
  }
}
.users-list {
  overflow: auto;
  max-height: 400px;
}

.chats-list {
  overflow: auto;
  /* max-height: 568px;
  min-height: 568px; */
  flex: 1;
}

.create-chat-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background-color: #006989;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s ease;
  align-items: center;
  border: 1px transparent solid;

  &:hover {
    background-color: #006989ad;
    border: 1px #dedede solid;
  }
}

.email {
  opacity: 0.7;
  font-size: 13px;
}

.create-chat__label {
  font-weight: bold;
}

.chats-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: 300ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
  //transform: translateX(50px) scaleY(0.5);
}

.list-enter-to {
  opacity: 1;
  //transform: translateX(0) scaleY(1);
}

.list-leave-active {
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  //transform: scaleY(0);
  transform-origin: center top;
}

.plus-btn {
  position: absolute;
  bottom: 45px;
  right: 36px;
  display: block;
  width: 80px;
  height: 80px;
  background: #006b90;
  border-radius: 50%;
  font-size: 0;
  border: 0;

  &::before {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    background-image: url("../../resource/img/plus-big.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover,
  &:focus {
    background: lighten(#006b90, 10%);
  }
}

.help-icon {
  position: absolute;
}

.form-control {
  background-color: var(--main-card-color);
  &:focus {
    background-color: var(--main-card-color);
    color: var(--main-text-color);
  }
}

.chats-chat-component {
  height: 100%;
  max-height: 100%;
  overflow-y: hidden !important;
}
</style>
